/** @jsx jsx */
import { graphql } from "gatsby";
import { Menu } from "gatsby-theme-menu";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetNavigation" }>;
}

const NavigationWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <Menu
        items={widget.navigationShape?.menu?.items}
        direction={widget.navigationShape?.direction}
      />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetNavigation on WidgetNavigation {
    id
    __typename
    navigationShape {
      direction
      menu {
        items {
          label
          url
          page {
            slug
            parentType
            childPages {
              slug
              relatedEntity {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export default memo(NavigationWidget);
