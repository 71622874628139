import { responsive } from "@boxoffice/screenplay";
import { ANIMATION_DURATION } from "shared/constants";
import { getMostReadable, getMostVisible } from "shared/helpers/colors";
import { Theme, ThemeUIStyleObject } from "theme-ui";

const wrapper: ThemeUIStyleObject = {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  margin: (theme: Theme) =>
    responsive({
      xs: `calc(${theme?.space?.[1] || 0} * -1)`,
      md: `calc(${theme?.space?.[2] || 0} * -1)`,
    }),
};

const sxStyles: Record<
  "link" | "wrapper" | "wrapperVertical",
  ThemeUIStyleObject
> = {
  wrapper,
  wrapperVertical: {
    ...wrapper,
    display: "block",
  },
  link: {
    alignItems: "center",
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: (theme) =>
      getMostVisible(theme.rawColors?.background, [
        theme.rawColors?.primary,
      ]).toHexString(),
    backgroundColor: (theme) => theme.rawColors?.background,
    color: (theme) =>
      getMostReadable(theme.rawColors?.background).toHexString(),
    display: "flex",
    justifyContent: "center",
    minHeight: responsive({ xs: "40px", md: "50px" }),
    padding: responsive({ xs: 1, md: 2 }),
    paddingX: responsive({ xs: 2, md: 3 }),
    margin: responsive({ xs: 1, md: 2 }),
    textAlign: "center",
    wordBreak: "break-word",
    fontSize: responsive({ xs: 1, md: 2 }),
    transition: `all ${ANIMATION_DURATION}`,
    "&:hover, &:focus, &.active, a:hover &, a:focus &, a.active &": {
      textDecoration: "none",
      borderColor: (theme) =>
        getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.accent,
        ]).toHexString(),
    },
  },
};

export default sxStyles;
