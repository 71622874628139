/** @jsx jsx */
import React, { memo } from "react";
import Link from "shared/components/Link";
import { preparePortalMenuItem } from "shared/helpers/menu/portalItem";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { Maybe } from "shared/utils/ts";
import { jsx } from "theme-ui";

import sxStyles from "./styles";

interface Props {
  readonly direction?: "HORIZONTAL" | "VERTICAL" | null;
  readonly items?: Maybe<
    ReadonlyArray<
      Maybe<{
        readonly __typename?: "MenuItem";
        readonly label?: Maybe<string>;
        readonly url?: Maybe<string>;
        readonly page?: Maybe<{
          readonly __typename?: "Page";
          readonly slug: string;
        }>;
      }>
    >
  >;
}

const Menu: React.FC<Props> = ({ items, direction }) => {
  const [selectedTheaterId] = useSelectedTheaterId();

  return (
    <div
      sx={
        direction === "VERTICAL" ? sxStyles.wrapperVertical : sxStyles.wrapper
      }
    >
      {items?.map((item) => {
        if (!item) {
          return null;
        }

        const preparedItem = preparePortalMenuItem(item, selectedTheaterId);

        const slug = preparedItem.url || `/${preparedItem.page?.slug}`;
        return (
          <Link
            key={preparedItem.label || ""}
            to={slug}
            // partiallyActive={slug !== "/"}
            sx={sxStyles.link}
          >
            {preparedItem.label || "—"}
          </Link>
        );
      })}
    </div>
  );
};

export default memo(Menu);
